<script>
  import ActiveCountry from '@/components/country-switcher/ActiveCountry.vue';
  import CountrySwitcher from '@/components/country-switcher/CountrySwitcher.vue';
  import Logout from '@/components/header/Logout.vue';
  import ActiveGiftCard from '@/components/header/ActiveGiftCart.vue';

  export default {
    components: {
      ActiveCountry,
      CountrySwitcher,
      Logout,
      ActiveGiftCard,
    },
    props: {
      logoUrl: {
        type: String,
        required: true,
      },
      links: {
        type: Array,
        required: true,
      },
    },
    data: () => ({
      showCountrySwitcher: false,
    }),
    computed: {
      hasAnyGiftCards() {
        console.warn(this.$activeGiftCard);
        return this.$activeGiftCard?.giftCards?.length > 0;
      },
      rootPathRedirectUrl() {
        return this.$channel.rootPathRedirectUrl || '';
      },
    },
    methods: {
      toggleCountrySwitcher() {
        this.showCountrySwitcher = !this.showCountrySwitcher;
      },
    },
  };
</script>

<template>
  <div class="desktop-header">
    <div class="desktop-header__content">
      <div class="desktop-header__logo">
        <router-link
          :to="rootPathRedirectUrl"
          class="desktop-header__logo-link">
          <img
            :src="logoUrl"
            alt="logo" />
        </router-link>
      </div>
      <div class="desktop-header__actions">
        <div
          v-if="hasAnyGiftCards"
          class="desktop-header__log-out">
          <logout />
          <div class="desktop-header__log-out-bar"></div>
        </div>
        <active-country
          :is-country-switcher-visible="showCountrySwitcher"
          @click.native="toggleCountrySwitcher()" />
        <country-switcher
          v-if="showCountrySwitcher"
          class="desktop-header__country-switcher"
          row-class="desktop-header__country-switcher-item" />
      </div>
    </div>
    <div class="desktop-header__links">
      <div
        v-for="(link, index) in links"
        :key="index"
        class="desktop-header__link">
        <router-link :to="link.url">{{ link.caption }}</router-link>
      </div>
    </div>
    <div
      class="desktop-header__other-actions"
      v-if="hasAnyGiftCards">
      <img
        src="../../static/icons/search.svg"
        class="desktop-header__search"
        @click="$mitt.emit('Search:Open')" />
      <active-gift-card />
    </div>
  </div>
</template>

<style>
  .desktop-header {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: auto;
    padding: 1rem;
    max-width: calc(var(--site-max-width) + 2rem);
    height: 113px;
  }

  .desktop-header__content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    height: 42px;
  }

  .desktop-header__logo {
    width: 100%;
    height: 29px;
    display: flex;
    justify-content: space-around;
    align-self: center;
  }

  .desktop-header__logo img {
    height: 29px;
  }

  .desktop-header__actions {
    width: 21%;
    display: flex;
    position: absolute;
    right: 1rem;
    justify-content: flex-end;
    color: var(--color-black);
    height: 21px;
  }

  .desktop-header__country-switcher {
    position: absolute;
    right: 0;
    top: 54px;
    background-color: var(--color-white);
    color: var(--color-black);
    z-index: 100;
    padding: 0.75rem 0.75rem 0.25rem 0.75rem;
    box-shadow: var(--basic-shadow);
  }

  .desktop-header__country-switcher-item {
    padding-bottom: 0.5rem;
  }

  .desktop-header__country-switcher-item:hover {
    color: var(--color-gray-900);
  }

  .desktop-header__links {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    line-height: 22px;
  }

  .desktop-header__log-out {
    display: flex;
    align-items: center;
    margin-right: 15px;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 15px;
  }

  .desktop-header__log-out-bar {
    height: 21px;
    border-right: 1px solid var(--color-black);
    margin-left: 15px;
  }

  .desktop-header__link {
    font-family: 'Mulish Medium', Arial, Helvetica, sans-serif;
    padding: 0 0.5rem;
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  .desktop-header__link a,
  .desktop-header__link a:visited {
    text-decoration: none;
    color: var(--color-black);
  }

  .desktop-header__other-actions {
    position: absolute;
    right: 1rem;
    bottom: 16px;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 25px;
  }

  .desktop-header__search {
    width: 21px;
    cursor: pointer;
  }
</style>
