import { defineCommand, defineReceiver } from '@drapejs/invoker';
import { request, query } from '@motillo/drapejs-litium';
import { gql } from 'graphql-request';

const takeProductsCount = 48;

export const commands = {
  searchGifts: defineCommand<{
    categorySystemId: string;
    path: string;
    searchPhrase: any;
    cacheKey: string;
  }>('searchGifts'),
};

export const receivers = {
  searchGifts: defineReceiver(commands.searchGifts, async function (command, data) {
    const result = await request(
      this.cache,
      query(
        gql`
          query productSearch(
            $url: String!
            $categorySystemId: String!
            $searchPhrase: String
            $take: Int
          ) {
            session(url: $url) {
              productSearch(
                take: $take
                categorySystemId: $categorySystemId
                searchPhrase: $searchPhrase
              ) {
                take
                totalHits
                categorySystemId
                products {
                  url
                  productName
                  brandName
                  imageIds
                  comingSoon
                }
              }
            }
          }
        `
      ),
      {
        url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
        categorySystemId: command.categorySystemId,
        searchPhrase: command.searchPhrase,
        take: takeProductsCount
      }
    );

    return result.session.productSearch;
  }),
};
