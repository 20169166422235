<script>
import useWebsiteTexts from "@/composables/useWebsiteTexts";

export default {
  setup() {
    const { websiteText } = useWebsiteTexts();

    return { websiteText };
  },
  computed: {
    cartRow() {
      return this.$cart?.rows?.[0];
    },
    cartRowProductName() {
      return this.cartRow?.variantName || this.cartRow?.baseProductName || "";
    },
    checkoutUrl() {
      return this.$channel?.checkoutUrl || "#";
    },
  },
};
</script>

<template>
  <div class="active-gift-card">
    <div v-if="!cartRow" class="active-gift-card__content">
      <img src="../../static/icons/cart-empty.svg" class="active-gift-card__icon" />
      <div class="active-gift-card__text">
        {{ websiteText("getmygift__header__nogiftcardselected").value }}
      </div>
    </div>
    <router-link
      :title="cartRowProductName"
      :to="checkoutUrl"
      v-else
      class="active-gift-card__content active-gift-card__content--full"
    >
      <img src="../../static/icons/cart-full.svg" class="active-gift-card__icon" />
      <div class="active-gift-card__text">
        {{ cartRowProductName }}
      </div>
    </router-link>
  </div>
</template>

<style>
.active-gift-card {
  display: flex;
  align-items: center;
  background-color: #EBE5DA;
  z-index: 1;
  justify-content: center;
}

.active-gift-card__content {
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
  user-select: none;
  text-decoration: none;
  align-items: center;
}

.active-gift-card__content .active-gift-card__text {
  color: var(--color-black);
}

.active-gift-card__content--full {
  cursor: pointer;
}

.active-gift-card__icon {
  width: 24px;
}

.active-gift-card__text,
.active-gift-card__text-caption {
  display: none;
}

@media (--desktop) {
  .active-gift-card {
    width: 165px;
    border: 1px solid var(--color-standard);
    border-radius: 2px;
    height: 40px;
    padding: 0 20px;
  }

  .active-gift-card__text {
    font-family: var(--font-mulish-semi-bold);
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    user-select: none;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1em;
    overflow: hidden;
  }

  .active-gift-card__text-caption {
    display: inline-block;
  }
}
</style>
