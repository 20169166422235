import create from './main';
import Worker from 'worker-loader!./web-worker';

const app = create(
  {
    litiumBaseUrl: process.env.LITIUM_URL || '',
  },
  () => new Worker()
);

app.mount('#app');