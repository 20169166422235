import { defineReceiver, defineCommand } from '@drapejs/invoker';
import { gql } from 'graphql-request';
import { request, query } from '@motillo/drapejs-litium';

export const commands = {
  changeCountry: defineCommand<{
    url: string;
    countryId: string;
    pageId: string;
  }>('changeCountry'),
};

export const receivers = {
  changeCountry: defineReceiver(
    commands.changeCountry,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(gql`
          mutation setCountry($url: String!, $countryId: String!, $pageId: String!) {
            session(url: $url) {
              setCountry(countryId: $countryId, pageId: $pageId) {
                error
                redirectUrl
              }
            }
          }
        `),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
          countryId: command.countryId,
          pageId: command.pageId || '',
        }
      );

      return result.session.setCountry;
    },
    'litium'
  ),
};
