<script setup lang="ts">
import mitt from 'mitt';
import { onServerPrefetch, ref, inject, provide, onMounted } from 'vue';
import {
  getServerItemKey,
  subscribeKey,
  getItemKey,
  bind,
} from '@drapejs/core';
import { usePageView } from '@motillo/tracking-gtm';

import {
  cartKey,
  channelKey,
  countrySwitcherKey,
  emitterKey,
  headerKey,
  footerKey,
  activeGiftCardKey,
} from '../keys';

import StandardLayout from '@/layouts/StandardLayout.vue';

const getServerItem = inject(getServerItemKey);
const getItem = inject(getItemKey);
const subscribe = inject(subscribeKey);

const emitter = mitt();
provide(emitterKey, emitter);

const cart = ref(getItem?.('__cart'));
provide(cartKey, cart);

const activeGiftCard = ref(getItem?.('__activeGiftCard'));
provide(activeGiftCardKey, activeGiftCard);

const channel = ref(getServerItem?.('__channel'));
provide(channelKey, channel);

const header = ref(getServerItem?.('__header'));
provide(headerKey, header);

const footer = ref(getServerItem?.('__footer'));
provide(footerKey, footer);

const countrySwitcher = ref(getServerItem?.('__countrySwitcher'));
provide(countrySwitcherKey, countrySwitcher);

onServerPrefetch(async () => {
  header.value = await getItem?.('__header');
  countrySwitcher.value = await getItem?.('__countrySwitcher');
  footer.value = await getItem?.('__footer');
  channel.value = await getItem?.('__channel');
});

usePageView(async () => {
  return {
    channel: channel.value?.systemId,
  };
});

onMounted(async () => {
  subscribe?.('__channel', (val: any) => {
    channel.value = val;
  });
  channel.value = await getItem?.('__channel');

  subscribe?.(
    '__activeGiftCard',
    (val: any) => {
      activeGiftCard.value = val;
    },
    { immediate: true }
  );
  activeGiftCard.value = await getItem?.('__activeGiftCard');

  subscribe?.('__cart', (val: any) => {
    cart.value = val;
  });
  cart.value = await getItem?.('__cart');

  subscribe?.(
    '__countrySwitcher',
    (val: any) => {
      countrySwitcher.value = val;
    },
    { immediate: true }
  );

  subscribe?.(
    '__header',
    (val: any) => {
      header.value = val;
    },
    { immediate: true }
  );

  subscribe?.(
    '__footer',
    (val: any) => {
      footer.value = val;
    },
    { immediate: true }
  );
});
</script>

<template>
  <StandardLayout />
</template>
