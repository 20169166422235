import { ref, inject } from 'vue';

import { activeGiftCardKey } from '@/keys';

import * as giftcard from '@/connectors/litium/giftcard';

import useContext from '@/composables/useContext';
import useExecuteCommand from '@/composables/useExecuteCommand';
import useWebsiteTexts from '@/composables/useWebsiteTexts';
import useCartTracking from '@/composables/useCartTracking';

export default function () {
  const { cart } = useContext();
  const { executeCommand } = useExecuteCommand();
  const { websiteText } = useWebsiteTexts();
  const { reportRemoveFromCart } = useCartTracking();

  const requestPending = ref(false);

  async function activateGiftCard({ codes, clear }: any) {
    try {
      requestPending.value = true;

      const response = await executeCommand(giftcard.commands.activateGiftCard, {
        codes,
        clear,
      });

      const { error } = response;

      if (error != 'NONE') {
        throw error;
      }

      reportLoginEvent();

      return response;
    } catch (err) {
      console.error(err);
      throw websiteText('giftcard__activation_error').value;
    } finally {
      requestPending.value = false;
    }
  }

  async function removeAllGiftCards() {
    try {
      requestPending.value = true;
      const previousRow = { ...(cart.value?.rows?.[0] || {}) };
      const response = await executeCommand(giftcard.commands.clearGiftCard, {});

      reportRemoveFromCart(previousRow);

      return response;
    } catch (err) {
      console.error(err);
      throw websiteText('giftcard__activation_error').value;
    } finally {
      requestPending.value = false;
    }
  }

  return {
    requestPending,
    activateGiftCard,
    removeAllGiftCards,
    activeGiftCard: inject(activeGiftCardKey, ref()),
  };
}

declare var dataLayer: any;

function reportLoginEvent() {
  if (typeof dataLayer === 'undefined') {
    return;
  }
  const dataToPush = {
    event: 'login',
  };

  dataLayer.push(dataToPush);
}